import { graphql, useStaticQuery } from "gatsby";
import { PollenOptionsSettings } from "../Types/PollenPosts.type";

const PollenRightRailCTA = (): {
  pollenOptionsSettings: PollenOptionsSettings;
} => {
  const {
    wp: { pollenOptionsSettings },
  } = useStaticQuery(graphql`
    {
      wp {
        pollenOptionsSettings {
          pollenRightRailO {
            pollenRightRailCtaText
            pollenRightRailHeadline
            pollenRightRailSubcopy
          }
        }
      }
    }
  `);
  return {
    pollenOptionsSettings,
  };
};

export default PollenRightRailCTA;
