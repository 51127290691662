import React from "react";
import { PageProps, graphql } from "gatsby";

import { getRandomInt } from "../../utils/getRandomInt";
import { ProductAdQuery } from "../../assets/StaticQueries/ProductAd.query";
import DefaultPollenLayout from "../../layouts/DefaultPollenLayout";
import PostContainer from "../../containers/PostContainer/PostContainer";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { deIndexSeo } from "../../utils/deIndexSeo";

type DataProps = {
  allWpPost: {
    nodes: Queries.WpPostConnection["nodes"];
  };
  wpPost: Queries.WpPost;
};

const PostPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const {
    allWpPost: { nodes: postsWithSameCategory },
    wpPost: post,
  } = data;
  const { allWpProductAd } = ProductAdQuery();
  const productAdIndex = getRandomInt(allWpProductAd.nodes.length);
  const productAd = allWpProductAd.nodes[productAdIndex];
  const moreStoriesPosts = postsWithSameCategory.slice(
    3,
    postsWithSameCategory.length
  );

  const popularArticles = postsWithSameCategory.slice(0, 3);

  return (
    <DefaultPollenLayout pathname={location.pathname}>
      {post.seo && <SEO post={deIndexSeo(post as unknown as Queries.WpPage)} />}
      <PostContainer
        moreStoriesPosts={moreStoriesPosts}
        popularArticles={popularArticles}
        post={post}
        productAd={productAd}
      />
    </DefaultPollenLayout>
  );
};

export default PostPage;

export const POST_PAGE_QUERY = graphql`
  query PostDetailPageQuery($postsWithSameCategory: [String], $id: String!) {
    wpPost(id: { eq: $id }) {
      ...ArticleCtaPFragment
      ...ArticleCtaPoFragment
      ...PollenPostSidebarCtaFragment
      ...PostFragment
      ...PostSeoFragment
    }
    allWpPost(filter: { id: { in: $postsWithSameCategory } }) {
      nodes {
        ...ArticleCtaPoFragment
        ...PollenPostSidebarCtaFragment
        ...PostFragment
      }
    }
  }
`;
