import { useStaticQuery, graphql } from "gatsby";

const PollenPostSidebarCtaQuery =
  (): Queries.WpPollenOptionsSettings_Pollenpostsidebarcta => {
    const {
      wp: {
        pollenOptionsSettings: { pollenPostSidebarCta },
      },
    } = useStaticQuery(graphql`
      {
        wp {
          pollenOptionsSettings {
            pollenPostSidebarCta {
              bannerHeadline
              primaryCtaText
              primaryCtaUrl
              secondaryCtaText
              secondaryCtaUrl
              ctaImage {
                altText
                gatsbyImage(
                  width: 800
                  formats: [WEBP, AUTO]
                  outputPixelDensities: 2
                  quality: 100
                )
              }
            }
          }
        }
      }
    `);

    return pollenPostSidebarCta;
  };

export default PollenPostSidebarCtaQuery;
