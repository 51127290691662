import React from "react";
import { ImageMediaItemUrl } from "@types";

import ModalTrigger from "../../Buttons/ModalTrigger";
import RightArrow from "../../SVGIcons/RightArrowSmall";

import articleCtaStyles from "./ArticleCta.module.scss";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
export interface ArticleCtaProps {
  articleCtaMainCopy: string;
  gated?: boolean | null;
  articleCtaLink: string | null;
  articleCtaText: string | null;
  articleCtaImage: ImageMediaItemUrl;
  isPartner?: boolean;
  className: string;
  isPollenPost?: boolean;
  classNameArticleCTA?: string;
  classNameArticleDrop?: string;
}

const ArticleCta: React.FC<ArticleCtaProps> = ({
  articleCtaMainCopy,
  articleCtaImage,
  articleCtaText,
  articleCtaLink,
  gated,
  className,
  isPartner,
  isPollenPost,
  classNameArticleCTA,
  classNameArticleDrop,
}) => (
  <div
    className={`${articleCtaStyles.articleCtaContainer} ${className} ${
      isPartner && articleCtaStyles.gatedBanner
    }`}
    data-testid="article-cta"
  >
    <div
      className={`${articleCtaStyles.articleCtaBackdrop} ${
        classNameArticleDrop && classNameArticleDrop
      }`}
    ></div>
    <div
      className={`${articleCtaStyles.articleCta} ${
        classNameArticleCTA && classNameArticleCTA
      }`}
    >
      <figure>
        {isPollenPost ? (
          <GatsbyImage
            image={articleCtaImage.gatsbyImage as IGatsbyImageData}
            alt={articleCtaImage.altText || ""}
          />
        ) : (
          <img
            data-testid="article-cta-image"
            alt={articleCtaImage.altText}
            src={articleCtaImage.localFile.publicURL}
          />
        )}
      </figure>

      <div className={articleCtaStyles.articleCtaCopy}>
        <h3
          className={articleCtaStyles.articleCtaHeadline}
          data-testid="article-cta-main-copy"
        >
          {articleCtaMainCopy}
        </h3>
        {gated ? (
          <ModalTrigger
            modalTrigger="resource-gated"
            data-testid="article-cta-btn"
          >
            Download now
            <RightArrow />
          </ModalTrigger>
        ) : (
          <a
            data-testid="article-cta-btn"
            aria-label="Download Free"
            href={`https://www.simplepractice.com/${articleCtaLink}`}
            download
            className={articleCtaStyles.articleLink}
          >
            {articleCtaText}
            <RightArrow />
          </a>
        )}
      </div>
    </div>
  </div>
);

export default ArticleCta;
