import React from "react";
import PostItem from "../../../containers/PollenSectionContainer/BlogCards/PostItem/PostItem";

import styles from "./PopularArticles.module.scss";

const PopularArticles: React.FC<{ posts: Queries.WpPostConnection["nodes"] }> =
  ({ posts }) => {
    return (
      <div className={`full-width`} data-testid="div:PopularArticles">
        <h2 className={`${styles.garamond} ${styles.popularArticlesTitle}`}>
          Popular Articles
        </h2>

        <div className={styles.blogRelated}>
          {posts.map((post, index) => (
            <PostItem key={index} post={post} />
          ))}
        </div>
      </div>
    );
  };

export default PopularArticles;
