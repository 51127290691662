import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { Button } from "../Buttons";
import { mxpPrimaryCtaClicked } from "../../utils/mixpanelEvents/mixpanelEvents";
import PollenPostSidebarCtaQuery from "../../assets/StaticQueries/PollenPostSidebarCta.query";

import styles from "./PollenPostSidebarCta.module.scss";
import { marketingCtaClicked } from "@utils/segment/marketingCtaClicked";

type PollenPostSidebarCtaType = {
  pageLevelData?: Queries.WpPollenOptionsSettings_Pollenpostsidebarcta;
};

const PollenPostSidebarCta: React.FC<PollenPostSidebarCtaType> = ({
  pageLevelData,
}) => {
  const {
    bannerHeadline,
    ctaImage,
    primaryCtaText,
    primaryCtaUrl,
    secondaryCtaText,
    secondaryCtaUrl,
  } =
    (pageLevelData?.bannerHeadline && pageLevelData) ||
    PollenPostSidebarCtaQuery();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    mxpPrimaryCtaClicked(
      e.currentTarget.getAttribute("href")!,
      e.currentTarget.textContent!
    );

    if (primaryCtaText?.toLowerCase()?.includes("start for")) {
      marketingCtaClicked({
        text: primaryCtaText,
        url: window.location.pathname,
        target: primaryCtaUrl || "",
      });
    }
  };

  return (
    <aside
      data-testid="pollen-post-sidebar-cta"
      className={styles.pollenPostSidebarCta}
    >
      <section className={styles.headlineWrapper}>
        <div className={styles.iconHeadlineContainer}>
          <svg className={styles.bannerIcon}>
            <use
              data-testid="banner-icon"
              href="/icons/sp-logo.svg#sp-logo-forest"
            ></use>
          </svg>

          <span
            className={styles.bannerHeadline}
            data-testid="banner-headline"
            dangerouslySetInnerHTML={{ __html: bannerHeadline! }}
          />
        </div>

        <GatsbyImage
          alt={ctaImage?.altText!}
          className={styles.ctaImage}
          data-testid="cta-image"
          image={ctaImage?.gatsbyImage!}
        />
      </section>

      <section className={styles.ctasWrapper}>
        <Button
          data-testid="primary-cta"
          buttonLink={primaryCtaUrl!}
          buttonText={primaryCtaText!}
          buttonType="link"
          variant="primary-blue"
          onClick={handleClick}
        />

        <Button
          data-testid="secondary-cta"
          buttonLink={secondaryCtaUrl!}
          buttonText={secondaryCtaText!}
          buttonType="link"
          variant="primary-white"
          onClick={handleClick}
        />
      </section>
    </aside>
  );
};

export default PollenPostSidebarCta;
