import React, { memo, useState } from "react";
import { Link, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import MoreStories from "../PollenSectionContainer/MoreStories/MoreStories";

import PollenPostSidebarCta from "../../components/PollenPostSidebarCta";
import PopularArticles from "./PopularArticles/PopularArticles";
import SearchInput from "../../components/SearchInput/SearchInput";
import CtaEmailBanner from "../../components/Banners/CtaEmailBanner/CtaEmailBanner";
import PrimaryButtonWithArrow from "../../components/Buttons/PrimaryButtonWithArrow/PrimaryButtonWithArrow";
import FacebookIcon from "../../components/SVGIcons/FacebookIcon/FacebookIcon";
import LinkedinIcon from "../../components/SVGIcons/LinkedinIcon/LinkedinIcon";
import TwitterIcon from "../../components/SVGIcons/TwitterIcon/TwitterIcon";
import parser, { DOMNode } from "html-react-parser";
import { ProductAdNode } from "@types";

import styles from "./PostContainer.module.scss";
import "./PostContainer.scss";
import ArticleCta from "../../components/Shortcodes/CTAs/ArticleCta";

type PostContainerType = {
  post: Queries.WpPost;
  moreStoriesPosts: Queries.WpPostConnection["nodes"];
  productAd: ProductAdNode;
  popularArticles: Queries.WpPostConnection["nodes"];
};

const ELEMENT_NODE = 1;

const PostContainer: React.FC<PostContainerType> = ({
  post,
  moreStoriesPosts,
  productAd,
  popularArticles,
}) => {
  const [search, setSearch] = useState("");
  const gatsbyImageData = post.featuredImage?.node?.gatsbyImage;

  const handleShortcodes = (node: DOMNode) => {
    const isElementNode = node.nodeType === ELEMENT_NODE;
    if (node.type && node.tagName === "p" && isElementNode) {
      const [child] = node.children;

      if (child && child.data && child?.data.trim() === "") {
        return <p className={styles.paragraphSpacing}>{child.data}</p>;
      }
    }

    if (node.type && node.type === "tag" && isElementNode) {
      const [child] = node.children;

      if (
        child &&
        child.parent.nodeType === ELEMENT_NODE &&
        child.next &&
        child.next.nodeType === ELEMENT_NODE
      ) {
        const shortcodeClass = child.parent.attribs.class;

        if (shortcodeClass === "article-cta__wrapper") {
          return (
            <ArticleCta
              gated={post.articleCtaP.gatedHidesCtaTextAndCtaLink}
              articleCtaImage={post.articleCtaP.articleCtaImage}
              articleCtaLink={post.articleCtaP.articleCtaLink}
              articleCtaText={post.articleCtaP.articleCtaText}
              articleCtaMainCopy={post.articleCtaP.articleCtaMainCopy}
              isPollenPost
              className={styles.articleContainer}
              classNameArticleCTA={styles.articleCTA}
              classNameArticleDrop={styles.articleDrop}
            />
          );
        }
      }
    }

    return node;
  };

  const parseReactElements = parser(post.content, {
    replace: node => handleShortcodes(node),
  });

  const productAdsC =
    typeof post?.articleCtaPo?.productAd?.at === "function" &&
    post?.articleCtaPo?.productAd?.at(0)?.productAdsC;

  const hasMicrocopy = productAdsC && productAdsC?.bannerAdsAssociatedMicrocopy;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setSearch(value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    navigate(`/search?term=${search}`);
  };

  const isDev = process.env.GATSBY_ENVIRONMENT === "dev";

  const shareURL = isDev
    ? "https://spgatsbystg.wpengine.com"
    : "https://www.simplepractice.com";

  const url = `${shareURL}${post?.uri}`;

  return (
    <section>
      <article>
        <header className={`full-width ${styles.postHeader}`}>
          <h1 className={styles.garamond}>{post.title}</h1>
        </header>
        <div className={`full-width ${styles.postAuthorContainer}`}>
          <div className={`${styles.bclist} ${styles.authorName}`}>
            <div className={styles.blogPostContainer}>
              <div className={styles.blogPostMeta}>
                <div className={styles.blogAvatar}>
                  <img alt="avatar" src={post?.author?.node?.avatar?.url!} />
                </div>
                <div className={styles.blogAuthorName}>
                  <div>
                    <Link
                      to={post?.author?.node.uri!}
                      dangerouslySetInnerHTML={{
                        __html: post?.author?.node.name!,
                      }}
                    />
                  </div>
                  <p className={styles.blogDate}>{post.date}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.bcside}></div>
        </div>

        <div className={`full-width ${styles.blogCatGrid}`}>
          <div className={`${styles.garamond} ${styles.bclist}`}>
            <section>
              <GatsbyImage
                loading="eager"
                className={styles.blogImage}
                image={gatsbyImageData!}
                alt={post.featuredImage?.node?.altText || ""}
              />
            </section>
            <div className={`${styles.garamond} ${styles.blogContent}`}>
              {parseReactElements}
            </div>
            <div className={styles.socialSharing}>
              <a
                href={`https://facebook.com/sharer/sharer.php?u=${url}`}
                target="_blank"
                rel="nofollow noopener noreferrer"
                aria-label=""
              >
                <img
                  src="/icons/facebook.png"
                  alt="Facebook"
                  className={styles.iconImage}
                />
              </a>
              <a
                href={`https://twitter.com/intent/tweet/?text=${post?.title}&url=${url}`}
                target="_blank"
                rel="nofollow noopener noreferrer"
                aria-label=""
              >
                <img
                  src="/icons/twitter.png"
                  alt="Twitter"
                  className={styles.iconImage}
                />
              </a>
              <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}&title=${post?.title}&summary=${post?.title}`}
                target="_blank"
                rel="nofollow noopener noreferrer"
                aria-label=""
              >
                <img
                  src="/icons/linkedin.png"
                  alt="Linkedin"
                  className={styles.iconImage}
                />
              </a>
            </div>

            <div className={styles.blogDivider}>
              {productAdsC && (
                <>
                  <div
                    className={styles.blogBannerCta}
                    style={{
                      backgroundColor: productAdsC?.bannerAdsBackgroundColor!,
                    }}
                  >
                    <h5
                      className={styles.garamond}
                      dangerouslySetInnerHTML={{
                        __html: productAdsC?.bannerAdsHeadline!,
                      }}
                    />
                    <PrimaryButtonWithArrow
                      buttonText={productAdsC?.bannerAdsCtaText!}
                      buttonLink={post?.articleCtaPo?.productAdCtaLink!}
                      style={{
                        backgroundColor:
                          productAdsC?.bannerAdsCtaBackgroundColor!,
                      }}
                    />
                    <div
                      className={`${styles.garamond} ${styles.checkIconContainer}`}
                    >
                      {productAdsC?.bannerAdsCheckboxList?.map(
                        (item, index) => (
                          <div key={index} className={styles.checkOption}>
                            <svg
                              className="icon"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <title>List Checkmark</title>
                              <path d="M9.87938 14.6219L7.27688 12.0194C6.98438 11.7269 6.51187 11.7269 6.21937 12.0194C5.92688 12.3119 5.92688 12.7844 6.21937 13.0769L9.35438 16.2119C9.64688 16.5044 10.1194 16.5044 10.4119 16.2119L18.3469 8.27687C18.6394 7.98437 18.6394 7.51187 18.3469 7.21937C18.0544 6.92688 17.5819 6.92688 17.2894 7.21937L9.87938 14.6219Z"></path>
                            </svg>
                            <div className={styles.checkOptionCopy}>
                              {item?.copy}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <div className={styles.blogBannerImage}>
                      <GatsbyImage
                        className={styles.img}
                        alt={productAdsC?.bannerAdsImage?.altText!}
                        image={productAdsC?.bannerAdsImage?.gatsbyImage!}
                      />
                    </div>
                  </div>

                  {hasMicrocopy && (
                    <div className={`full-width ${styles.blogBannerAds}`}>
                      <div className={styles.blogBannerAdsAssociatedMicrocopy}>
                        {productAdsC.bannerAdsAssociatedMicrocopy}
                      </div>
                    </div>
                  )}
                </>
              )}
              <hr />
            </div>
          </div>

          <div className={styles.bcside}>
            <SearchInput
              value={search}
              onChange={handleChange}
              onSubmit={handleSubmit}
            />

            <PollenPostSidebarCta pageLevelData={post?.pollenPostSidebarCta!} />

            <MoreStories
              posts={moreStoriesPosts}
              isNotMainPage
              className={styles.moreStories}
            />

            <CtaEmailBanner />
          </div>
        </div>

        <PopularArticles posts={popularArticles} />
      </article>
    </section>
  );
};

export default memo(PostContainer);
