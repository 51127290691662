import React from "react";
import ModalTrigger from "../../Buttons/ModalTrigger";
import PollenRightRailCTA from "../../../assets/StaticQueries/PollenRightRailCTA.query";
import styles from "./CtaEmailBanner.module.scss";

const CtaEmailBanner = () => {
  const {
    pollenOptionsSettings: { pollenRightRailO },
  } = PollenRightRailCTA();
  return (
    <div className={styles.ctaEmailContainer}>
      <h2>{pollenRightRailO.pollenRightRailHeadline}</h2>
      <p>{pollenRightRailO.pollenRightRailSubcopy}</p>
      <ModalTrigger modalTrigger="pollen-lead">
        {pollenRightRailO.pollenRightRailCtaText}
      </ModalTrigger>
    </div>
  );
};

export default CtaEmailBanner;
